body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-table .ant-table-tbody td {
  word-break: break-all;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #24833a;
}
a:active {
  color: #24833a;
}

body,
p,
div {
  font-size: 14px;
}

:where(.css-dev-only-do-not-override-11wvfg7).ant-pagination
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
  background: none;
  color: #24833a;
}

:where(.css-dev-only-do-not-override-11wvfg7).ant-pagination
  .ant-pagination-prev:hover
  .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-11wvfg7).ant-pagination
  .ant-pagination-next:hover
  .ant-pagination-item-link {
  background: none;
  color: #24833a;
}

html {
  scroll-padding-top: 69px;
}
